$btn-focus-box-shadow: none !important;
$theme-colors: (
  "primary": #37784f,
  "dark": #3f3d56,
  "light": #ebf2ee,
);

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@300;400;700&display=swap");
@import "~bootstrap/scss/bootstrap";

* {
  transition-duration: 300ms;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #37784f !important;
}

html {
  font-family: "Lato", sans-serif;
  color: #3f3d56;
}

body {
  font-family: "Lato", sans-serif;
  color: #3f3d56;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

.widen {
  letter-spacing: 0.1rem;
}

.head {
  font-family: "Montserrat", sans-serif;
}

.gradient-bg {
  background: rgb(215, 228, 220);
  background: -moz-linear-gradient(180deg, rgba(215, 228, 220, 0.8) 0%, rgba(255, 255, 250, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(215, 228, 220, 0.8) 0%, rgba(255, 255, 250, 0) 100%);
  background: linear-gradient(180deg, rgba(215, 228, 220, 0.8) 0%, rgba(255, 255, 250, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d7e4dc",endColorstr="#fffffa",GradientType=1);
}
.bg-gradient-primary {
  background: rgb(215, 228, 220);
  background: -moz-linear-gradient(90deg, rgba(63, 61, 86, 1) 0%, rgba(55, 120, 79, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(63, 61, 86, 1) 0%, rgba(55, 120, 79, 1) 100%);
  background: linear-gradient(90deg, rgba(63, 61, 86, 1) 0%, rgba(55, 120, 79, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc107", endColorstr="#e3010f", GradientType=1);
}

.text-gradient {
  background: linear-gradient(to right, rgba(63, 61, 86, 1) 0%, rgba(55, 120, 79, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main_marquee_div {
  overflow: hidden;
  white-space: nowrap;
}

#marquee_div1 {
  display: inline-block;
  animation: marquee 60s linear infinite;
  animation-delay: -30s;
}

#marquee_div2 {
  display: inline-block;
  animation: marquee2 60s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

.stat-rotate {
  animation: statSpin 5s cubic-bezier(0.22, 0.56, 0.57, 0.56) infinite;
}

@keyframes statSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-bg {
  background-color: #fffffa;
  background-image: url("../src/img/bg.svg");
  background-attachment: fixed;
  background-size: cover;
}

.hidden {
  opacity: 0;
}

@media (min-width: 992px) {
  .col-lg-2-5 {
    flex: 0 0 20.8333333%;
    max-width: 20.8333333%;
  }
}

.services-bg {
  background-color: #d7e4dc;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23dbe7e0' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23dfeae3' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23e3ece7' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23e7efea' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23EBF2EE' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ecf6ee' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23f0faee' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f6fcf1' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fcfef5' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23FFFFFA' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.timeline {
  padding: 1rem 3rem;
}
.timestamp {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 500ms;
}
.status {
  display: flex;
  justify-content: center;
  position: relative;
}
.status span {
  margin-top: 20px;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: small;
}
.status span:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #ebf2ee;
  border-radius: 25px;
  border: 2px solid #37784f;
  position: absolute;
  top: -13px;
  left: calc(50% - 13px);
  transition-duration: 500ms;
}
.swiper-container {
  width: 100%;
  overflow: hidden;
}
.swiper-slide {
  width: auto;
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
}

.swiper-slide.active .status span:before {
  background: #37784f;
}

@media (min-width: 992px) {
  .timeline {
    display: flex;
  }
  .status {
    border-top: 2px solid #37784f;
    border-left: none;
  }
}
